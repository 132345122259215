import React from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { userModalState } from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";
import useSWR from "swr";
import moment from "moment";
import Button from "../elements/Button";
import { useNavigate, useParams } from "react-router-dom";

import DefaultProfile from "../../assets/images/proflie-image.svg";
import { useTranslation } from "react-i18next";

const UserModal = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { t } = useTranslation("");

  const [modal, setModal] = useRecoilState(userModalState);

  const { data } = useSWR(modal !== null && `/user/detail?userId=${modal?.id}`);
  const { data: profile } = useSWR(modal !== null && "/profile");

  const handleClose = () => {
    setModal(null);
  };

  if (modal === null) return null;

  return (
    <ModalWrapper onClose={handleClose} position="top">
      <Wrapper>
        <Profile>
          <img
            src={data?.profileUrl ? data?.profileUrl : DefaultProfile}
            alt=""
            className="profile"
          />
          {data?.badgeImage && (
            <img src={data?.badgeImage} alt="" className="tag" />
          )}
        </Profile>
        <Name>{data?.name}</Name>
        {data?.message && <Intro>{data?.message}</Intro>}
        <Info>
          <p>
            <span>{t("info_emplyee")}</span>
            {data?.employeeNumber}
          </p>
          <p>
            <span>{t("info_depart")}</span>
            {data?.department}
          </p>
          {data?.birth &&
            data?.showBirth !== null &&
            data?.showBirth !== undefined &&
            data?.showBirth !== 1 && (
              <p>
                <span>{t("info_email")}</span>
                {moment(data?.birth).format("MM월 DD일")}
              </p>
            )}
        </Info>
        {profile?.id !== modal?.id && (
          <Button
            onClick={() => {
              navigate(`/card`);
              handleClose();
            }}
          >
            {t("info_send")}
          </Button>
        )}
        <Button styleType="line" onClick={handleClose}>
          {t("info_close")}
        </Button>
      </Wrapper>
    </ModalWrapper>
  );
};

export default UserModal;

const Info = styled.div`
  margin: 20px 0 40px;
  padding: 20px;
  border-radius: 10px;
  background: #fafafa;

  & > p {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;

    & > span {
      min-width: 80px;
      width: 80px;
      font-weight: 400;
      margin-right: 12px;
    }

    &:nth-of-type(n + 2) {
      margin-top: 16px;
    }
  }

  @media (max-width: 1024px) {
    margin: 20px 0;
  }
`;

const Intro = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 1.6;
  text-align: center;
  color: black;
`;

const Name = styled.div`
  margin: 20px 0 8px;
  font-size: 20px;
  font-weight: 700;
  line-height: 1;
  color: ${(props) => props.theme.color.gray1};
  text-align: center;
`;

const Profile = styled.div`
  width: fit-content;
  position: relative;
  margin: auto;

  & > img {
    border-radius: 100px;
    object-fit: cover;
  }

  & > img.profile {
    width: 101px;
    height: 101px;
    border: 1px solid ${(props) => props.theme.color.gray8};
  }

  & > img.tag {
    position: absolute;
    bottom: 0;
    right: -5px;
    width: 40px;
    height: 40px;
  }
`;

const Wrapper = styled.div`
  max-width: 420px;
  width: 100%;
  padding: 40px 30px 30px;
  background: white;
  border-radius: 20px;

  & > button:nth-of-type(2) {
    margin-top: 10px;
  }

  @media (max-width: 1024px) {
    padding: 30px 20px;
  }
`;
