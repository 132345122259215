import React from "react";
import styled from "styled-components";
import { ImageUploadType } from "../../types/types";

import { ReactComponent as Upload } from "../../assets/icons/upload-icon.svg";
import { useTranslation } from "react-i18next";

const ImageUpload = ({
  label,
  placeholder,
  image,
  onChange,
}: ImageUploadType) => {
  const { t } = useTranslation("");

  const handleFile = (e: any) => {
    const files = e.target.files;
    const file: any = files && files[0];
    onChange && onChange(file);
  };

  return (
    <Label>
      {label && <p>{label}</p>}
      <InputBox className={image ? "active" : ""}>
        {image ? image : placeholder ? placeholder : `${t("post_imageText")}`}
        <Upload />
        <input type="file" accept="image/*" onChange={handleFile} />
      </InputBox>
    </Label>
  );
};

export default ImageUpload;

const InputBox = styled.div`
  width: 100%;
  height: 44px;
  padding: 10px 14px;
  border-radius: 10px;
  background: #fafafa;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: ${(props) => props.theme.color.gray5};
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.active {
    font-weight: 600;
    color: ${(props) => props.theme.color.gray1};
  }

  & > input {
    display: none;
  }

  & > svg {
    width: 24px;
    min-width: 24px;
    height: 24px;
  }
`;

const Label = styled.label`
  display: block;
  width: 100%;

  & > p {
    font-size: 13px;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 6px;
  }
`;
