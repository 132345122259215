import React from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { twoButtonModalState } from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";
import Button from "../elements/Button";
import { PopupTitle, PopupText } from "../elements/Typography";
import { useTranslation } from "react-i18next";

const TwoButtonModal = () => {
  const [modal, setModal] = useRecoilState(twoButtonModalState);

  const { t } = useTranslation("");

  const handleCancle = () => {
    if (window.location.href.includes("find_id")) {
      modal?.onConfirm && modal.onConfirm();
    } else if (modal?.title === "로그인 필요") {
      modal?.onClose && modal.onClose();
    }
    setModal(null);
  };

  const handleClose = () => {
    modal?.onClose && modal?.onClose();
    setModal(null);
  };

  const handleConfirm = () => {
    modal?.onConfirm && modal.onConfirm();
    setModal(null);
  };
  if (modal === null) return null;

  return (
    <ModalWrapper onClose={handleCancle}>
      <Wrapper className={modal?.findId ? "findID" : ""}>
        <PopupTitle className="center">{modal.title}</PopupTitle>
        <PopupText
          className="center"
          dangerouslySetInnerHTML={{ __html: modal.text }}
        />
        <FlexBox>
          <Button onClick={handleConfirm}>
            {modal?.confirmText || `${t("user_ok")}`}
          </Button>
          <Button styleType="line" onClick={handleClose}>
            {modal?.cancleText || `${t("info_close")}`}
          </Button>
        </FlexBox>
      </Wrapper>
    </ModalWrapper>
  );
};

export default TwoButtonModal;

const FlexBox = styled.div`
  & > button:nth-of-type(n + 2) {
    margin-top: 10px;
  }
`;

const Wrapper = styled.div`
  max-width: 420px;
  width: 100%;
  padding: 50px 30px 30px;
  background: #ffffff;
  border-radius: 20px;

  & > p {
    margin: 10px 0 40px;
  }

  @media (max-width: 1024px) {
    padding: 30px 20px 20px;

    & > p {
      margin: 10px 0 30px;
    }
  }
`;
