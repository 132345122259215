import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { commentModalState } from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";
import useInfiniteScroll from "../../utils/useInfiniteScroll";
import useSWRInfinite from "swr/infinite";
import CommentList from "../list/CommentList";
import { api } from "../../utils/api";
import { mergeList } from "../../utils/CommonFunction";

import { ReactComponent as Close } from "../../assets/icons/popup-close-icon.svg";
import { useTranslation } from "react-i18next";

const CommentModal = () => {
  const [modal, setModal] = useRecoilState(commentModalState);
  const { t } = useTranslation("");

  const [listData, setListData] = useState<any[]>([]);
  const [page, setPage] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);

  const ref = useRef(null);
  const content = useRef(null);

  const [intersecting, loadFinished, setLoadFinished] = useInfiniteScroll(ref);

  const getKey = (pageIndex: number, previousPageData: any[] | null) => {
    if (previousPageData && !previousPageData.length) {
      //@ts-ignore
      setLoadFinished(true);
      return null;
    }

    if (modal === null) return null;

    return `/comment?page=${pageIndex + 1}&targetType=${modal?.type}&targetId=${
      modal?.id
    }`;
  };

  const fetcher = async (url: string) => {
    const res = await api.get(url);
    setPage(res?.data?.data?.pageInfo?.totalPages);
    setTotal(res?.data?.data?.pageInfo?.totalElements);

    return res.data?.data?.comments;
  };

  //@ts-ignore
  const { data, mutate, size, setSize } = useSWRInfinite(getKey, fetcher);

  useEffect(() => {
    if (data?.length) {
      setListData(mergeList(data));
    }
  }, [data]);

  const infiniteScroll = (e: React.UIEvent<HTMLElement>) => {
    //@ts-ignore
    let scrollTop = e.target.scrollTop;

    if (!loadFinished && page > size && listData?.length >= size * 10) {
      //@ts-ignore
      const scrollHeight = e.target.scrollHeight;
      //@ts-ignore
      const clientHeight = e.target.clientHeight;

      if (scrollTop + clientHeight >= scrollHeight - scrollTop) {
        setSize((prev: number) => prev + 1);
      }
    }
  };
  useEffect(() => {
    if (content.current !== null) {
      //@ts-ignore
      content.current.addEventListener("scroll", infiniteScroll, true);

      return () => {
        try {
          //@ts-ignore
          content.current.removeEventListener("scroll", infiniteScroll, true);
        } catch (error) {}
      };
    }
  }, [content, listData, page, size, loadFinished]);

  const handleClose = () => {
    setModal(null);
  };

  if (modal === null) return null;

  return (
    <ModalWrapper onClose={handleClose} position="full">
      <Wrapper>
        <Title>
          {t("feed_commend")} {total}
          <Close onClick={handleClose} />
        </Title>
        <Content ref={content}>
          {listData?.length > 0 &&
            listData.map((item, i) => (
              <CommentList
                key={"comment_list" + i}
                item={item}
                mutate={mutate}
                userId={modal?.userId}
              />
            ))}
        </Content>
      </Wrapper>
    </ModalWrapper>
  );
};

export default CommentModal;

const Content = styled.div`
  width: 100%;
  margin-top: 30px;

  @media (max-width: 1024px) {
    margin-top: 0;
    max-height: calc(100% - 54px);
    overflow: auto;
  }
`;

const Title = styled.h1`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 22px;
  font-weight: 700;
  line-height: 1;
  color: ${(props) => props.theme.color.gray1};

  & > svg {
    width: 24px;
    height: 24px;
  }

  @media (max-width: 1024px) {
    position: relative;
    justify-content: center;
    width: 100%;
    height: 54px;
    font-size: 14px;
    font-weight: 600;

    & > svg {
      position: absolute;
      top: 15px;
      right: 20px;
      padding: 2px;
    }
  }
`;

const Wrapper = styled.div`
  max-width: 960px;
  width: 100%;
  padding: 30px 30px 60px;
  background: white;
  border-radius: 20px;

  @media (max-width: 1024px) {
    padding: 0;
    max-width: 100%;
    height: 100%;
    border-radius: 0;
  }
`;
