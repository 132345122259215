import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useRecoilState, useSetRecoilState } from "recoil";
import { alertModalState, bedgeModalState } from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";
import Button from "../elements/Button";
import useSWR from "swr";
import { BedgeListType } from "../../types/api";
import moment from "moment";
import { api } from "../../utils/api";

import { ReactComponent as Close } from "../../assets/icons/popup-close-icon.svg";
import { ReactComponent as Check } from "../../assets/icons/bedge-check-icon.svg";
import Empty from "../elements/Empty";
import { useTranslation } from "react-i18next";

const BedgeModal = () => {
  const [modal, setModal] = useRecoilState(bedgeModalState);
  const [bedge, setBedge] = useState<number>();
  const [pc, setPc] = useState(true);
  const { t } = useTranslation("");

  const setAlertModal = useSetRecoilState(alertModalState);

  const { data } = useSWR(modal !== null && `/my/badge`);

  useEffect(() => {
    if (modal !== null) setBedge(modal?.select);
    else setBedge(undefined);
  }, [modal]);

  useEffect(() => {
    setPc(window.outerWidth > 1024);

    window.addEventListener("resize", () => {
      setPc(window.outerWidth > 1024);
    });
  }, []);

  const handleClose = () => {
    setModal(null);
  };

  const handleConfirm = async () => {
    const formData = new FormData();
    formData.append("badgeId", String(bedge));

    const {
      data: { success, message, data },
    } = await api.post("/badge/setting", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (success) {
      handleClose();
      modal?.mutate && modal?.mutate();
      setAlertModal({
        title: `${t("mypage_changeDone")}`,
        text: `${t("mypage_changeDoneText")}`,
      });
    } else {
      window.alert(message);
    }
  };

  if (modal === null) return null;

  return (
    <ModalWrapper onClose={handleClose} position="full">
      <Wrapper>
        <Title>
          {t("mypage_bedge")}
          <Close onClick={handleClose} />
        </Title>
        <Content>
          {data && data?.badges?.length > 0 ? (
            data?.badges?.map((item: BedgeListType, i: number) => (
              <Bedge
                key={"bedge_list" + i}
                className={bedge === item.id ? "active" : ""}
                onClick={() => setBedge(item.id)}
              >
                <ImageArea>
                  <Image src={item?.image} />
                  {bedge === item.id && <Check />}
                </ImageArea>
                <Text>{pc ? item?.title : item?.title?.slice(0, 8)}</Text>
                <Text className="date">
                  {moment(item?.createdAt).format("YYYY-MM-DD 획득")}
                </Text>
              </Bedge>
            ))
          ) : (
            <Empty type="badge" text={`${t("mypage_use_bedge")}`} />
          )}
        </Content>
        <ButtonArea>
          <Button
            onClick={handleConfirm}
            disabled={
              !data ||
              data?.badges?.length <= 0 ||
              bedge === undefined ||
              bedge === null
            }
          >
            {t("mypage_selectDone")}
          </Button>
        </ButtonArea>
        <Button
          onClick={handleConfirm}
          disabled={
            !data ||
            data?.badges?.length <= 0 ||
            bedge === undefined ||
            bedge === null
          }
        >
          {t("mypage_selectDone")}
        </Button>
      </Wrapper>
    </ModalWrapper>
  );
};

export default BedgeModal;

const ButtonArea = styled.div`
  width: 100%;
  padding: 20px;
  display: none;

  @media (max-width: 1024px) {
    display: block;
  }
`;

const Text = styled.div`
  margin: 10px 0;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  color: ${(props) => props.theme.color.gray1};
  display: -webkit-box;
  word-wrap: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;

  .active & {
    color: ${(props) => props.theme.color.sub};
  }

  &.date {
    color: ${(props) => props.theme.color.gray1};
    opacity: 0.5;
  }

  @media (max-width: 1024px) {
    margin: 14px 0 0;
    font-size: 13px;
    line-height: 1.5;
    -webkit-line-clamp: 1;

    &.date {
      display: none;
    }
  }
`;

const Image = styled.img`
  width: 88px;
  height: 88px;
  border-radius: 80px;
  object-fit: cover;

  @media (max-width: 1024px) {
    width: 60px;
    height: 60px;
  }
`;

const ImageArea = styled.div`
  position: relative;
  width: fit-content;
  height: fit-content;
  margin: auto;

  & > svg {
    position: absolute;
    top: 0;
    right: 0;
  }

  @media (max-width: 1024px) {
    & > svg {
      width: 24px;
      height: 24px;
    }
  }
`;

const Bedge = styled.div`
  max-width: calc(33.33% - 20px);
  width: 100%;
  margin-right: 30px;
  cursor: pointer;

  &:nth-of-type(3n) {
    margin-right: 0;
  }

  &:nth-of-type(n + 4) {
    margin-top: 45px;
  }

  @media (max-width: 1024px) {
    max-width: calc(33.33% - 2.66px);
    margin-right: 4px;

    &:nth-of-type(n + 4) {
      margin-top: 20px;
    }
  }
`;

const Content = styled.div`
  margin: 40px 0 30px;
  max-height: 496px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow: auto;

  @media (max-width: 1024px) {
    margin: 0;
    max-height: calc(100% - 54px - 90px);
    height: 100%;
    align-content: flex-start;
    padding: 20px;
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 22px;
  font-weight: 700;
  line-height: 1;
  color: ${(props) => props.theme.color.gray1};

  & > svg {
    width: 24px;
    height: 24px;
  }

  @media (max-width: 1024px) {
    position: relative;
    justify-content: center;
    width: 100%;
    height: 54px;
    font-size: 14px;
    font-weight: 600;

    & > svg {
      position: absolute;
      top: 15px;
      right: 20px;
      padding: 2px;
    }
  }
`;

const Wrapper = styled.div`
  max-width: 480px;
  width: 100%;
  padding: 30px;
  background: white;
  border-radius: 20px;

  @media (max-width: 1024px) {
    max-width: 100%;
    height: 100%;
    padding: 0;
    border-radius: 0;

    & > button {
      display: none;
    }
  }
`;
