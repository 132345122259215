import { atom } from "recoil";
import { OptionType } from "../types/types";

type TwoButtonModalType = {
  title: string;
  text: string;
  findId?: string;
  confirmText?: string;
  cancleText?: string;
  onConfirm?: () => void;
  onClose?: () => void;
} | null;

export const twoButtonModalState = atom<TwoButtonModalType>({
  key: `twoButtonModalState`,
  default: null,
});

type alertModalType = {
  title: string;
  text: string;
  confirmText?: string;
  onConfirm?: () => void;
  onClose?: () => void;
} | null;

export const alertModalState = atom<alertModalType>({
  key: `alertModalState`,
  default: null,
});

type bedgeModalType = {
  select: number;
  mutate: () => void;
} | null;

export const bedgeModalState = atom<bedgeModalType>({
  key: `bedgeModalState`,
  default: null,
});

type changePasswordModalType = {
  visible: boolean;
} | null;

export const changePasswordModalState = atom<changePasswordModalType>({
  key: `changePasswordModalState`,
  default: null,
});

type completeModalType = {
  title: string;
} | null;

export const completeModalState = atom<completeModalType>({
  key: `completeModalState`,
  default: null,
});

type likeModalType = {
  id: number;
  type: "feed" | "vcard" | "comment";
  count: string | number;
} | null;

export const likeModalState = atom<likeModalType>({
  key: `likeModalState`,
  default: null,
});

type userModalType = {
  id: number;
  onClose?: () => void;
} | null;

export const userModalState = atom<userModalType>({
  key: `userModalState`,
  default: null,
});

type commentModalType = {
  id: number;
  type: "feed" | "vcard";
  count: string | number;
  userId: number;
} | null;

export const commentModalState = atom<commentModalType>({
  key: `commentModalState`,
  default: null,
});

type joinModalType = {
  id: number;
  mutate: () => void;
} | null;

export const joinModalState = atom<joinModalType>({
  key: `joinModalState`,
  default: null,
});

type challengeModalType = {
  id: number;
  mutate: () => void;
} | null;

export const challengeModalState = atom<challengeModalType>({
  key: `challengeModalState`,
  default: null,
});

type feedPostModalType = {
  edit: boolean;
  id?: number;
} | null;

export const feedPostModalState = atom<feedPostModalType>({
  key: `feedPostModalState`,
  default: null,
});

type vcardPostModalType = {
  cardId: number;
  cardThumb: string;
  id?: number;
  user?: string;
} | null;

export const vcardPostModalState = atom<vcardPostModalType>({
  key: `vcardPostModalState`,
  default: null,
});

type detailModalType = {
  id: number;
  type: "feed" | "vcard";
  userId: number;
  mutate: () => void;
} | null;

export const detailModalState = atom<detailModalType>({
  key: `detailModalState`,
  default: null,
});

type cardDetailModalType = {
  id: number;
} | null;

export const cardDetailModalState = atom<cardDetailModalType>({
  key: `cardDetailModalState`,
  default: null,
});

type termModalType = {
  type: string;
} | null;

export const termModalState = atom<termModalType>({
  key: `termModalState`,
  default: null,
});

type selectModalType = {
  options: OptionType[];
  value?: string;
  onSelect?: (value: OptionType) => void;
} | null;

export const selectModalState = atom<selectModalType>({
  key: `selectModalState`,
  default: null,
});

type toastModalType = {
  text: string;
} | null;

export const toastModalState = atom<toastModalType>({
  key: `toastModalState`,
  default: null,
});

type PostModalType = {
  visible: boolean;
  onClick?: (postcode: string, address: string) => void;
} | null;

export const postModalState = atom<PostModalType>({
  key: `postModalState`,
  default: null,
});

// 모바일

type commentMenuModalType = {
  handleEdit: () => void;
  handleDelete: () => void;
} | null;

export const commentMenuModalState = atom<commentMenuModalType>({
  key: `commentMenuModalState`,
  default: null,
});

type filterMenuModalType = {
  option: OptionType[];
  onChange: (option: OptionType) => void;
  select: string | undefined;
} | null;

export const filterMenuModalState = atom<filterMenuModalType>({
  key: `filterMenuModalState`,
  default: null,
});

type feedMenuModalType = {
  onEdit: () => void;
  onDelete: () => void;
} | null;

export const feedMenuModalState = atom<feedMenuModalType>({
  key: `feedMenuModalState`,
  default: null,
});
