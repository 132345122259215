import React from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { termModalState } from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";
import useSWR from "swr";

import { ReactComponent as Close } from "../../assets/icons/popup-close-icon.svg";
import { useTranslation } from "react-i18next";

const TermModal = () => {
  const [modal, setModal] = useRecoilState(termModalState);
  const { t } = useTranslation("");

  const { data } = useSWR(modal !== null && `/terms?type=${modal?.type}`);

  const handleClose = () => {
    if (window.location.href.includes("?term"))
      window.location.replace(window.location.href.split("?term")[0]);
    setModal(null);
  };

  if (modal === null) return null;

  return (
    <ModalWrapper onClose={handleClose} position="full">
      <Wrapper className="ql-snow">
        <Title>
          {modal?.type === "service"
            ? `${t("footer_service")}`
            : `${t("footer_private")}`}
          <Close onClick={handleClose} />
        </Title>
        <Content
          className="ql-editor"
          dangerouslySetInnerHTML={{ __html: data?.contents }}
        />
      </Wrapper>
    </ModalWrapper>
  );
};

export default TermModal;

const Content = styled.div`
  width: 100%;
  margin-top: 30px;
  white-space: pre-wrap;

  @media (max-width: 1024px) {
    margin: 0;
    padding: 20px;
    width: 100%;
    height: calc(100% - 54px);
    overflow: auto;
  }
`;

const Title = styled.h1`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 22px;
  font-weight: 700;
  line-height: 1;
  color: ${(props) => props.theme.color.gray1};

  & > svg {
    width: 24px;
    height: 24px;
  }

  @media (max-width: 1024px) {
    position: relative;
    justify-content: center;
    width: 100%;
    height: 54px;
    font-size: 14px;
    font-weight: 600;

    & > svg {
      position: absolute;
      top: 15px;
      right: 20px;
      padding: 2px;
    }
  }
`;

const Wrapper = styled.div`
  max-width: 960px;
  width: 100%;
  padding: 30px;
  background: white;
  border-radius: 20px;

  @media (max-width: 1024px) {
    max-width: 100%;
    height: 100%;
    padding: 0;
    border-radius: 0;
  }
`;
