import React, { useState } from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { changePasswordModalState } from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";
import Button from "../elements/Button";

import { ReactComponent as Close } from "../../assets/icons/popup-close-icon.svg";
import { api } from "../../utils/api";
import Input from "../elements/Input";
import { useTranslation } from "react-i18next";

const ChangePasswordModal = () => {
  const [modal, setModal] = useRecoilState(changePasswordModalState);
  const { t } = useTranslation("");
  const [origin, setOrigin] = useState<string>("");

  const [password, setPassword] = useState<string>("");
  const [passError1, setPassError1] = useState<string>("");

  const [passwordCheck, setPasswordCheck] = useState<string>("");
  const [passError2, setPassError2] = useState<string>("");

  const handleClose = () => {
    setModal(null);
  };

  const handleSubmit = async () => {
    const reg =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,20}$/;

    if (!reg.test(password)) setPassError1(`${t("user_PwError")}`);
    else if (password !== passwordCheck) setPassError2(`${t("user_PwDiff")}`);
    else {
      const formData = JSON.stringify({
        oldPassword: origin,
        password: password,
      });

      const {
        data: { success, message, data },
      } = await api.post("/new/password/modify", formData);

      if (success) {
        handleClose();
        window.location.reload();
      } else {
        window.alert(message);
      }
    }
  };

  if (modal === null) return null;

  return (
    <ModalWrapper onClose={handleClose} position="full">
      <Wrapper>
        <Title>
          {t("user_change")}
          <Close onClick={handleClose} />
        </Title>
        <InputArea>
          <Input
            label={t("user_now")}
            type="password"
            placeholder={t("user_nowPw")}
            value={origin}
            onChange={(e) => {
              let value = e.target.value.slice(0, 20);
              if (value.length <= 20) {
                setOrigin(value);
              }
            }}
          />
          <Input
            label={t("user_new")}
            type="password"
            placeholder={t("user_PwText")}
            errorText={passError1 !== "" ? passError1 : undefined}
            value={password}
            onChange={(e) => {
              let value = e.target.value.slice(0, 20);
              if (value.length <= 20) {
                setPassword(value);
                setPassError1("");
              }
            }}
          />
          <Input
            label={t("user_chkNew")}
            type="password"
            placeholder={t("user_again")}
            errorText={passError2 !== "" ? passError2 : undefined}
            value={passwordCheck}
            onChange={(e) => {
              let value = e.target.value.slice(0, 20);
              if (value.length <= 20) {
                setPasswordCheck(value);
                setPassError2("");
              }
            }}
          />
        </InputArea>
        <ButtonArea>
          <Button
            disabled={
              origin === "" ||
              password === "" ||
              passwordCheck === "" ||
              passError1 !== "" ||
              passError2 !== ""
            }
            onClick={handleSubmit}
          >
            {t("user_change")}
          </Button>
        </ButtonArea>
        <Button
          disabled={
            origin === "" ||
            password === "" ||
            passwordCheck === "" ||
            passError1 !== "" ||
            passError2 !== ""
          }
          onClick={handleSubmit}
        >
          {t("user_change")}
        </Button>
      </Wrapper>
    </ModalWrapper>
  );
};

export default ChangePasswordModal;
const ButtonArea = styled.div`
  width: 100%;
  padding: 20px;
  display: none;

  @media (max-width: 1024px) {
    display: block;
  }
`;

const InputArea = styled.div`
  margin: 30px 0 50px;

  & > label:nth-of-type(n + 2) {
    display: block;
    margin-top: 30px;
  }

  @media (max-width: 1024px) {
    width: 100%;
    height: calc(100% - 54px - 90px);
    margin: 0;
    padding: 20px;
    overflow: auto;

    & > label:nth-of-type(n + 2) {
      margin-top: 20px;
    }
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 22px;
  font-weight: 700;
  line-height: 1;
  color: ${(props) => props.theme.color.gray1};

  & > svg {
    width: 24px;
    height: 24px;
  }

  @media (max-width: 1024px) {
    position: relative;
    justify-content: center;
    width: 100%;
    height: 54px;
    font-size: 14px;
    font-weight: 600;

    & > svg {
      position: absolute;
      top: 15px;
      right: 20px;
      padding: 2px;
    }
  }
`;

const Wrapper = styled.div`
  max-width: 480px;
  width: 100%;
  padding: 30px;
  background: white;
  border-radius: 20px;

  @media (max-width: 1024px) {
    max-width: 100%;
    height: 100%;
    padding: 0;
    border-radius: 0;

    & > button {
      display: none;
    }
  }
`;
