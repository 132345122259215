import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ChildrenType } from "../../../types/types";
import Header from "./Header";
import Footer from "./Footer";
import Side from "./Side";
import { useNavigate } from "react-router-dom";
import { feedPostModalState } from "../../../utils/atom";
import { useSetRecoilState } from "recoil";
import useSWR from "swr";

import { ReactComponent as PostIcon } from "../../../assets/icons/post-icon.svg";
import { ReactComponent as Close } from "../../../assets/icons/float-close-icon.svg";
import { ReactComponent as Feed1 } from "../../../assets/icons/card-feed-icon.svg";
import { ReactComponent as Feed2 } from "../../../assets/icons/normal-feed-icon.svg";
import { useTranslation } from "react-i18next";

const MainWrapper = ({ children }: ChildrenType) => {
  const navigate = useNavigate();
  const { t } = useTranslation("");

  const setPostModal = useSetRecoilState(feedPostModalState);

  const scroll = useRef(null);
  const buttonRef = useRef(null);

  const [float, setFloat] = useState<boolean>(false);

  const { data: profile } = useSWR("/profile");

  useEffect(() => {
    if (buttonRef.current !== null) {
      const el: any = buttonRef.current;

      if (float) {
        el.style.display = "block";
        setTimeout(() => {
          el.style.opacity = "1";
        }, 100);
      } else {
        el.style.opacity = "0";
        setTimeout(() => {
          el.style.display = "none";
        }, 300);
      }
    }
  }, [float, buttonRef]);

  useEffect(() => {
    if (scroll.current !== null) {
      let el: any = scroll.current;

      el.scrollTop = 0;
    }
  }, [window.location.href, scroll]);

  return (
    <>
      <Header alarm={profile?.alarmCount} />
      <Scroll ref={scroll}>
        <Content>
          <Side />
          <Inner>{children}</Inner>
        </Content>
        <Footer />
      </Scroll>
      {window.location.href.includes("/feed") && (
        <>
          <ButtonArea ref={buttonRef}>
            <PostButton
              type="button"
              onClick={() => {
                setFloat(false);
                navigate(`/card`);
              }}
            >
              <span>
                <Feed1 />
              </span>
              <p>V-CARD</p>
            </PostButton>
            {profile?.isNotice === 1 && (
              <PostButton
                type="button"
                onClick={() => {
                  setFloat(false);
                  setPostModal({ edit: false });
                }}
              >
                <span>
                  <Feed2 />
                </span>
                <p> {t("post_feed")}</p>
              </PostButton>
            )}
          </ButtonArea>
          <Float
            className={float ? "open" : ""}
            onClick={() => setFloat(!float)}
          >
            {float ? (
              <>
                <Close />
                {t("post_cancle")}
              </>
            ) : (
              <>
                <PostIcon />
                {t("post_post")}
              </>
            )}
          </Float>
        </>
      )}
    </>
  );
};

export default MainWrapper;

const PostButton = styled.button`
  width: 150px;
  border-radius: 39px;
  background: ${(props) => props.theme.color.sub};
  margin-top: 6px;
  padding: 10px 12px 10px 16px;
  display: flex;
  align-items: center;

  & > p {
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    text-align: center;
    color: white;
  }

  & > span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin-right: 12px;
  }
`;

const ButtonArea = styled.div`
  position: fixed;
  bottom: 132px;
  right: 40px;
  display: none;
  opacity: 0;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Float = styled.div`
  position: fixed;
  bottom: 40px;
  right: 40px;
  width: 80px;
  height: 80px;
  padding: 15px;
  border-radius: 80px;
  background: ${(props) => props.theme.color.sub};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: 700;
  line-height: 1;
  color: white;

  & > svg {
    margin-bottom: 4px;
    width: 36px;
    height: 36px;
  }

  &.open {
    background: ${(props) => props.theme.color.gray1};
  }
`;

const Inner = styled.div`
  max-width: calc(100% - 430px);
  width: 100%;

  @media (max-width: 1400px) {
    max-width: calc(100% - 400px);
  }

  @media (max-width: 1200px) {
    max-width: calc(100% - 360px);
  }
`;

const Content = styled.div`
  display: flex;
  align-items: stretch;
`;

const Scroll = styled.div`
  width: 100%;
  height: calc(100% - 80px);
  overflow: auto;
`;
