import React from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { likeModalState } from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";
import LikeList from "../list/LikeList";
import useSWR from "swr";

import { ReactComponent as Close } from "../../assets/icons/popup-close-icon.svg";
import { LikeListType } from "../../types/api";
import { useTranslation } from "react-i18next";

const LikeModal = () => {
  const [modal, setModal] = useRecoilState(likeModalState);
  const { t } = useTranslation("");

  const { data } = useSWR(
    modal !== null &&
      `/like?targetType=${
        modal?.type === "comment" ? "comment" : "feed"
      }&targetId=${modal?.id}`
  );

  const handleClose = () => {
    setModal(null);
  };

  if (modal === null) return null;

  return (
    <ModalWrapper onClose={handleClose} position="full">
      <Wrapper>
        <Title>
          {t("feed_like")} {data?.users?.length}
          <Close onClick={handleClose} />
        </Title>
        <Content>
          {data &&
            data?.users.map((like: LikeListType, i: number) => (
              <LikeList key={"like_modal_list" + i} item={like} />
            ))}
        </Content>
      </Wrapper>
    </ModalWrapper>
  );
};

export default LikeModal;

const Content = styled.div`
  width: 100%;
  margin-top: 30px;

  & > div:nth-of-type(n + 2) {
    margin-top: 20px;
  }

  @media (max-width: 1024px) {
    margin-top: 0;
    padding: 20px;
    max-height: calc(100% - 54px);
    overflow: auto;
  }
`;

const Title = styled.h1`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 22px;
  font-weight: 700;
  line-height: 1;
  color: ${(props) => props.theme.color.gray1};

  & > svg {
    width: 24px;
    height: 24px;
  }

  @media (max-width: 1024px) {
    position: relative;
    justify-content: center;
    width: 100%;
    height: 54px;
    font-size: 14px;
    font-weight: 600;

    & > svg {
      position: absolute;
      top: 15px;
      right: 20px;
      padding: 2px;
    }
  }
`;

const Wrapper = styled.div`
  max-width: 500px;
  width: 100%;
  padding: 30px 30px 60px;
  background: white;
  border-radius: 20px;

  @media (max-width: 1024px) {
    padding: 0;
    max-width: 100%;
    height: 100%;
    border-radius: 0;
  }
`;
