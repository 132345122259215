import React from "react";
import styled from "styled-components";
import { ChallengeType } from "../../types/api";
import { useSetRecoilState } from "recoil";
import { completeModalState } from "../../utils/atom";
import { api } from "../../utils/api";
import { useTranslation } from "react-i18next";

const ChallengeList = ({
  item,
  mutate,
}: {
  item: ChallengeType;
  mutate: () => void;
}) => {
  const setCompleteModal = useSetRecoilState(completeModalState);
  const { t } = useTranslation("");

  const handleDone = async () => {
    const formData = new FormData();
    formData.append("challengeId", String(item?.id));

    const {
      data: { success, message, data: data2 },
    } = await api.post("/challenge/success", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (success) {
      setCompleteModal({ title: item?.title });
      mutate();
    } else {
      window.alert(message);
    }
  };

  return (
    <Wrapper>
      <Image src={item?.image} alt="" />
      <TextArea>
        <h1>{item?.title}</h1>
        <p>{item?.contents}</p>
        {/* <p>{item?.type === "auto" ? "자동 달성 챌린지" : "참가형 챌린지"}</p> */}
      </TextArea>
      {(item?.type === "auto" &&
        ((item?.status === 0 && item.percent >= 100) || item?.status === 1)) ||
      (item?.type === "enter" && (item?.status === 3 || item?.status === 4)) ? (
        <Complete
          type="button"
          disabled={item?.status === 1 || item?.status === 4}
          onClick={handleDone}
        >
          {t("challenge_complete")}
        </Complete>
      ) : (
        <Percent>
          {item.percent}%
          <div>
            <span
              style={{
                background:
                  item.percent === 100
                    ? "#2F80ED"
                    : item.percent >= 50
                    ? "#EDAC2F"
                    : "#9F9F9F",
              }}
            />
          </div>
        </Percent>
      )}
    </Wrapper>
  );
};

export default ChallengeList;

const Complete = styled.button`
  padding: 8px;
  border-radius: 6px;
  background: ${(props) => props.theme.color.sub};
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  color: white;

  &:disabled {
    opacity: 0.3;
  }

  @media (max-width: 1024px) {
    font-size: 13px;
  }
`;

const Percent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
  color: ${(props) => props.theme.color.gray1};
  width: 75px;

  & > div {
    padding: 5px;
    margin-left: 5px;

    & > span {
      display: flex;
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }
  }

  @media (max-width: 1024px) {
    font-size: 15px;

    & > div {
      margin-left: 2px;
    }
  }
`;

const TextArea = styled.div`
  width: calc(100% - 75px - 90px);
  margin-right: auto;

  & > h1 {
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
    color: ${(props) => props.theme.color.gray1};
  }

  & > p {
    margin-top: 8px;
    font-size: 13px;
    font-weight: 400;
    line-height: 1;
    color: ${(props) => props.theme.color.gray1};

    & img {
      max-width: 100%;
    }
  }

  @media (max-width: 1024px) {
    width: calc(100% - 65px - 90px);

    & > h1 {
      font-size: 15px;
    }

    & > p {
      margin-top: 6px;
    }
  }
`;

const Image = styled.img`
  width: 60px;
  height: 60px;
  margin-right: 14px;
  border-radius: 50%;
  object-fit: cover;

  @media (max-width: 1024px) {
    width: 54px;
    height: 54px;
    margin-right: 10px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  &:nth-of-type(n + 2) {
    margin-top: 24px;
  }

  @media (max-width: 1024px) {
    &:nth-of-type(n + 2) {
      margin-top: 20px;
    }
  }
`;
