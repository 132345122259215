import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { commentMenuModalState } from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";
import Button from "../elements/Button";
import { PopupTitle, PopupText } from "../elements/Typography";
import { useTranslation } from "react-i18next";

const CommentMenuModal = () => {
  const [modal, setModal] = useRecoilState(commentMenuModalState);
  const { t } = useTranslation("");

  const wrapperRef = useRef(null);

  useEffect(() => {
    if (wrapperRef.current !== null) {
      setTimeout(() => {
        //@ts-ignore
        wrapperRef.current.style.bottom = "0px";
      });
    }
  }, [wrapperRef, modal]);

  const handleClose = () => {
    //@ts-ignore
    wrapperRef.current.style.bottom = "-100%";

    setTimeout(() => {
      setModal(null);
    }, 200);
  };

  if (modal === null) return null;

  return (
    <ModalWrapper onClose={handleClose} position="bottom">
      <Wrapper ref={wrapperRef}>
        <List
          className="select"
          onClick={() => {
            handleClose();
            modal?.handleEdit && modal?.handleEdit();
          }}
        >
          {t("detail_commendEdit")}
        </List>
        <List
          onClick={() => {
            handleClose();
            modal?.handleDelete && modal?.handleDelete();
          }}
        >
          {t("detail_commendDel")}
        </List>
      </Wrapper>
    </ModalWrapper>
  );
};

export default CommentMenuModal;

const List = styled.div`
  width: 100%;
  height: 46px;
  padding: 0 20px;
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  color: ${(props) => props.theme.color.gray1};
  display: flex;
  align-items: center;

  &.select {
    font-weight: 700;
    color: ${(props) => props.theme.color.sub};
  }
`;

const Wrapper = styled.div`
  position: absolute;
  max-width: 100%;
  width: 100%;
  padding: 30px 0 0;
  background: white;
  border-radius: 20px 20px 0 0;
  bottom: -100%;
  transition: all 0.3s;
  z-index: 1;
`;
